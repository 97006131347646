<template>
  <perseu-card :title="titleDialog">
    <template slot="content">
      <v-form ref="form" @submit.prevent="save">
        <v-text-field
          label="Nome"
          v-model.trim="category.name"
          :rules="[$rules.required]"
        />
        <v-switch
          v-model="category.isSecondLevel"
          label="Categoria para Nível 2"
        />
        <select-lang v-model="category.lang" :rules="[$rules.required]" />
      </v-form>
    </template>
    <template slot="actions">
      <v-btn @click="save" color="primary">Salvar</v-btn>
    </template>
  </perseu-card>
</template>

<script>
import { create, update } from "@/services/categories-service";
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    titleDialog() {
      return (this.category.id ? "Editar " : "Nova ") + "Categoria";
    },
  },
  data: () => ({
    category: {},
  }),
  mounted() {
    this.category = this.value || {};
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;
      try {
        this.$store.dispatch("loading/openDialog");
        if (this.category.id) {
          const { data } = await update(this.category);
          this.$emit("update", data);
        } else {
          const { data } = await create(this.category);
          this.$emit("create", data);
        }
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
